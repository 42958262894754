import { e as c } from "./styled-40300750.js";
const i = "exact-prop: ​";
function u(t) {
  return process.env.NODE_ENV === "production" ? t : c({}, t, {
    [i]: (e) => {
      const r = Object.keys(e).filter((o) => !t.hasOwnProperty(o));
      return r.length > 0 ? new Error(`The following props are not supported: ${r.map((o) => `\`${o}\``).join(", ")}. Please remove them.`) : null;
    }
  });
}
function n(t, e) {
  return n = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function(o, p) {
    return o.__proto__ = p, o;
  }, n(t, e);
}
function O(t, e) {
  t.prototype = Object.create(e.prototype), t.prototype.constructor = t, n(t, e);
}
export {
  O as _,
  u as e
};
