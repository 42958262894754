import { i as e } from "./styled-40300750.js";
import { r as a } from "./createSvgIcon-a6c25c2e.js";
import t from "react/jsx-runtime";
var r = {}, o = e;
Object.defineProperty(r, "__esModule", {
  value: !0
});
var u = r.default = void 0, i = o(a()), d = t, f = (0, i.default)(/* @__PURE__ */ (0, d.jsx)("path", {
  d: "m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
}), "ArrowForward");
u = r.default = f;
export {
  u as d
};
